<script>
import ModularCard from "../molecules/ModularCard.vue";

export default {
  components: { ModularCard },
  name: "AppStepper",
  data() {
    return {
      keyList: [],
      form: {},
      step: 0,
    };
  },
  created() {
    // this is working properly
    this.step = this.currentStep;
    this.keyList = Object.keys(this.stepList);

    this.keyList.forEach((key) => {
      if (this.stepList[key][this.formKeys[key]] != undefined) {
        if (this.stepList[key][this.formKeys[key]].type === "form") {
          this.form[this.stepList[key][this.formKeys[key]].name] = {};
          let formFields = Object.keys(
            this.stepList[key][this.formKeys[key]].form
          );
          formFields.forEach((field) => {
            if (
              this.stepList[key][this.formKeys[key]].form[field].inputType !=
              "switch"
            ) {
              this.form[this.stepList[key][this.formKeys[key]].name][field] =
                "";
            } else {
              this.form[this.stepList[key][this.formKeys[key]].name][
                field
              ] = true;
            }
          });
        }
      }
    });
  },
  props: {
    currentStep: Number,
    stepList: Object,
    formKeys: Object,
    hasCancel: {
      type: Boolean,
      default: true,
    },
    /* stepList: [
          key: {
              position: Number,
              inputs: Array
          }
      ] */
  },
  watch: {
    stepList: {
      handler(val) {
        Object.keys(val).forEach((key) => {
          if (this.stepList[key][this.formKeys[key]] != undefined) {
            if (this.stepList[key][this.formKeys[key]].type === "form") {
              let formFields = Object.keys(
                this.stepList[key][this.formKeys[key]].form
              );
              this.form[this.stepList[key][this.formKeys[key]].name] = {};
              formFields.forEach((field) => {
                this.form[this.stepList[key][this.formKeys[key]].name][field] =
                  this.stepList[key][this.formKeys[key]].form[field].value;
              });
            }
          }
        });
        /* this.updateValue({ name: name, value: value }); */
      },
      deep: true,
      immediate: true,
    },
    step: {
      // watch it
      handler: function (val) {
        this.clickHeader(val);
      },
      deep: true,
      immediate: true,
    },
    currentStep: {
      // watch it
      handler: function () {
        this.step = Number(this.currentStep);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    hasTutorial() {
      return this.formKeys[1] == undefined;
    },
  },
  methods: {
    clickHeader(payload) {
      this.$emit("changeStep", Number(payload));
    },
    click() {
      this.$emit("click", this.form);
    },
    getText(key) {
      if (this.hasCancel) {
        if (key <= 1) {
          return "Cancelar";
        }
        /* if (key == 2 && hasTutorial) {
          return "Tutorial";
        } */
      }
      if (key == 2 && this.hasTutorial) {
        return "Tutorial";
      }
      return "Voltar";
    },
    isDisabled(key) {
      if (!this.hasCancel) {
        if (key <= 1) {
          return true;
        }
      }
      return false;
    },
    previous() {
      if (this.step > 1) {
        this.step = Number(this.step) - 1;
      } else {
        this.cancel();
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    next() {
      this.step = Number(this.step) + 1;
    },
    handleInput(event) {
      let fieldKeys = Object.keys(event.fields);
      fieldKeys.forEach((fieldKey) => {
        this.form[event.name][fieldKey] = event.fields[fieldKey];
      });
      this.$emit("change", this.form);
    },
    submit() {
      this.$emit("submit", this.form);
    },
    handleConversational(key) {
      if (key < this.keyList.length) {
        this.next();
      } else {
        this.submit();
      }
    },
  },
};
</script>

<template>
  <v-stepper
    v-model="step"
    style="width: 100%; min-height: min(calc(100% + 16vh), calc(100% + 92px))"
    non-linear
  >
    <v-stepper-items>
      <v-stepper-content
        v-for="key in keyList"
        v-bind:key="key"
        :step="key"
        class="pa-0"
        style="overflow: visible"
      >
        <modular-card
          :cardModules="stepList[key]"
          :buttonBackText="getText(key)"
          :buttonBackDisabled="isDisabled(key)"
          :buttonNextDisabled="key >= keyList.length"
          @next="next"
          @previous="previous"
          @submit="submit"
          @change="handleInput"
          @click="click"
        />
      </v-stepper-content>
    </v-stepper-items>
    <v-stepper-header>
      <v-stepper-step
        v-for="key in keyList"
        v-bind:key="key"
        :complete="step > key"
        editable
        :step="key"
        @click="clickHeader(key)"
      >
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<style lang="scss">
.v-stepper__header {
  box-shadow: none !important;
  margin: 0 auto;
  justify-content: center !important;
  overflow: visible !important;
  flex-wrap: nowrap;

  .v-stepper__step {
    padding: 24px 12px;
  }
}

.v-stepper__items {
  margin-top: 0.5em;
}

.v-stepper__items {
  max-width: 448px;
  margin: 0 auto;
}

.v-stepper__content,
.v-stepper__wrapper,
.v-stepper__items,
.v-stepper {
  overflow: visible !important;
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
